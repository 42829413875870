import React, { useContext, useEffect, useState } from 'react'
import Image from 'next/image'
import LogoImage from '../public/images/logo_color.svg'
import { useLoading } from '../LoadingContext'
import SVG from '../components/SVG'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import { useAuthAPI, useEpsilonAPI } from '../hooks/api.hooks'
import getConfig from 'next/config'
import Context from '../context'
import BranchIO from '../utils/branch-io'
import { useIonModal } from '@ionic/react'
import NewDevice from './newdevice'
import TermsConditionsModal from '../components/TermsConditionsModal'
import AccountLockedModal from '../components/AccountLockedModal'
import Link from 'next/link'
import { setPageViewData, setUserLoginLogoutEventData } from '../utils/analytics'

const { publicRuntimeConfig } = getConfig()
const branchIO: BranchIO = new BranchIO({})

const UserLogin = () => {
  const { register, setError, handleSubmit, formState: { errors, isDirty, isValid } } = useForm(
    {
      mode: 'onChange',
      defaultValues: { emailOrPhone: '', password: '', accountLock: '', rememberMe: true }
    }
  )
  const router = useRouter()
  const { dispatch, state } = useContext(Context)
  const authAPICall = useAuthAPI()
  const { setLoading } = useLoading()
  const [passwordType, setPasswordType] = useState('password')
  const [invalidCredential, setinvalidCredential] = useState(false)
  const [passwordEyeIcon, setPasswordEyeIcon] = useState('/images/icons/icon-eye-inactive.svg')
  const [savedFormData, setSavedFormData] = useState({})
  const [tempUserGUID, setTempUserGUID] = useState(null)
  const { redirectedURL, ...spread } = router.query
  const [acceptedTCVersion, setAcceptedTCVersion] = useState()
  const epsilonAPICall = useEpsilonAPI()
  let loginData
  let cleanedNumber

  const [present, dismiss] = useIonModal(NewDevice, { cpref: false, onSuccess: () => onSubmit(savedFormData), setLoading, user: tempUserGUID })
  const handleTCDismiss = () => dismissModal()
  const agreeTC = () => {
    state.gigyaScript.accounts.setAccountInfo({
      data: {
        TandC: {
          acceptedVersion: acceptedTCVersion
        }
      }
    })
    dismissModal()
    redirectedURL ? router.push({ pathname: `/${redirectedURL}`, query: { redirectedURL, ...spread } }) : router.push('/')
  }
  const [presentModal, dismissModal] = useIonModal(TermsConditionsModal, { handleTCDismiss, agreeTCVersion: agreeTC })
  const handleDismiss = () => {
    dismissAccountLocked()
  }
  const [presentAccountLocked, dismissAccountLocked] = useIonModal(AccountLockedModal, {
    modal: true,
    handleDismiss
  })

  const phoneCleaner = (emailOrPhone) => {
    const pattern = /^[+]?[(]?\d{3}[)]?[-\s.]*?\d{3}[-\s.]*?\d{4,6}$/im
    const matches = emailOrPhone.match(pattern)
    if (matches) {
      const cleaned = matches.map((match) => match.replace(/[\D\s]/g, ''))
      cleanedNumber = cleaned[0]
      return cleanedNumber
    }
  }
  const anonUID = state.user.UID

  const onSubmit = async (formData) => {
    setLoading(true)
    dismiss()
    setSavedFormData(formData)
    const emailOrPhone = formData.emailOrPhone
    const password = formData.password
    const rememberMe = formData.rememberMe
    const cleanedNumber = phoneCleaner(emailOrPhone)

    try {
      const data = await authAPICall('user', 'login', 'POST', {
        popkey: publicRuntimeConfig.POP_KEY
      }, {
        emailOrPhone: cleanedNumber || emailOrPhone,
        password: password,
        rememberme: rememberMe,
        Browser: 'Web'
      }, {
        deviceid: state.deviceId
      })

      if (data.error) {
        handleAuthError(data)
      } else {
        await handleLoginSuccess(data, emailOrPhone, password, anonUID)
      }
    } catch (error) {
      setLoading(false)
      setinvalidCredential(true)
    }
  }

  const handleAuthError = (data) => {
    setLoading(false)
    if (data.error === '9c6120c8-c408-4357-a32f-8a38d42d879d') {
      setTempUserGUID(data.UserToken)
      dispatch({ type: 'USER_CUS_DATA', payload: { ...state.user, phone: data.PhoneNumber } })
      present({ cssClass: 'modal--fullscreen', backdropDismiss: false })
    } else if (data.error === 'Account Temporarily Locked Out') {
      presentAccountLocked({ cssClass: 'modal--center' })
      setError('accountLock', { type: 'valid', message: 'Account temporarily lockedout' })
    } else {
      setinvalidCredential(true)
    }
  }

  const handleLoginSuccess = async (data, emailOrPhone, password, anonUID) => {
    setAcceptedTCVersion(data.TCVersion)
    loginData = data

    state.gigyaScript.accounts.login({
      loginID: cleanedNumber || emailOrPhone,
      password: password
    }, { callback: response => setAccountInfo(response, data) })

    await handlePostLogin(data)
  }

  const handlePostLogin = async (data) => {
    try {
      await epsilonAPICall('epsilon', 'offers/refresh', 'POST', {}, {},
        {
          'x-pop-loyaltyoffers': publicRuntimeConfig.LOYALTY_OFFERS_KEY,
          'x-posting-key': data.PhoneNumber,
          'x-user-id': data.UserToken
        })
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const setAccountInfo = (response, data) => {
    const userData = {
      UID: loginData.UserToken,
      appSessionToken: loginData.SessionGUID,
      userSessionToken: loginData.UserSessionToken,
      firstName: loginData.FirstName,
      lastName: loginData.LastName,
      email: loginData.EmailAddress,
      Rewards: loginData.Rewards,
      phone: loginData.PhoneNumber,
      authType: 1,
      zip: null
    }
    branchIO.logEvent('SignIn Completed', 'SignIn Completed', '', userData.UID)
    setUserLoginLogoutEventData()
    if (response.data?.userPreferences) {
      userData.zip = response.data.userPreferences.postalCode
    }

    dispatch({ type: 'USER_CUS_DATA', payload: userData })

    if (!data.NeedsAcceptTC) {
      redirectedURL
        ? router.push({ pathname: `/${redirectedURL}`, query: { redirectedURL, ...spread } }).then(() => {
          setLoading(false)
        })
        : router.push('/').then(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
      presentModal({ cssClass: 'modal--fullscreen', backdropDismiss: false })
    }
  }

  const homeClicked = () => {
    router.push('/')
  }

  useEffect(() => {
    const pageName = router.query?.clickedElement ? `${router.query?.clickedElement}|Sign In` : 'Sign In'
    setPageViewData(pageName, 'Sign In', 'Account')
  }, [])

  useEffect(() => {
    if (errors.emailOrPhone) {
      setinvalidCredential(false)
    }
  }, [errors.emailOrPhone])

  const toggle = () => {
    if (passwordType === 'password') {
      setPasswordType('text')
      setPasswordEyeIcon('/images/icons/icon-eye-active.svg')
      return
    }
    setPasswordType('password')
    setPasswordEyeIcon('/images/icons/icon-eye-inactive.svg')
  }

  const onRegisterNow = () => {
    router.query.redirectedURL
      ? router.push({ pathname: '/registration', query: { ...router.query } })
      : router.push('/registration')
  }

  const onSignedOut = (response) => {
    if (response.errorCode === 0) {
      homeClicked()
    } else {
      console.error('Error', response.errorMessage)
    }
  }

  const logUserOut = () => {
    if (state.user.UID) {
      const emptyUser = {
        UID: '',
        UidSignature: '',
        UidSignatureTimestamp: ''
      }
      dispatch({ type: 'USER_CUS_DATA', payload: emptyUser })
      state.gigyaScript.accounts.logout({ callback: onSignedOut })
      homeClicked()
    } else {
      const { redirectedURL, ...spread } = router.query
      router.query.redirectedURL
        ? router.push({ pathname: `/${router.query.redirectedURL}`, query: { ...spread } })
        : router.push('/')
    }
  }

  return (
    <div className='login-page'>
      <button className="close-button" onClick={logUserOut}><SVG name="close" label="Close" size="md" /></button>
      <div className="login-page__header">
        <button className="popshelf-button" onClick={homeClicked}>
          <Image width={150} height={33.5} alt="pOpshelf" src={LogoImage} className="title" />
        </button>
      </div>
      <div className="login-form">
        <h1>sign in</h1>
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <div className="login-form__email">
            <label>Email or Phone</label>
            <input type="text" {...register('emailOrPhone', { required: true })} placeholder="Ex. email@email.com" style={{ border: errors.emailOrPhone ? '1px solid red' : '' }} />
            {errors.emailOrPhone && <label className="error-label">Invalid loginID</label>}
            {invalidCredential && <label className="error-label">Invalid login or password</label>}
            {errors.accountLock && <label className="error-label">{errors.accountLock.message}</label>}
          </div>
          <div className="login-form__password">
            <label>Type password</label>
            <div className="password-field">
              <input type={passwordType} {...register('password', { required: true })} placeholder="Password" style={{ border: errors.password ? '1px solid red' : '' }} />
              <button type="button" className="eyeball" onClick={toggle} style={{ backgroundImage: `url(${passwordEyeIcon})` }}></button>
              {errors.password && <label className="error-label">Password is required</label>}
            </div>
          </div>
          <div className="login-form__section">
            <div className="login-form__section-remember-me">
              <label className="checkbox-wrapper">
                <input type="checkbox" {...register('rememberMe')} />
                <span className="checkbox-wrapper__check">
                  <SVG name='checkmark' label="checkbox" size="base" />
                </span>
                <span className="checkbox-wrapper__label-text">Remember me</span>
              </label>
            </div>
            <div className="login-form__section-forgot-pass">
            <Link href='/forgotpassword'><a className="forgot-pass">Forgot password?</a></Link>
            </div>
          </div>
          <div className="signin-button">
            <button type="submit" className="sign-in" disabled={!isDirty || !isValid}>SIGN IN</button>
          </div>
        </form>
        <div className="register-button">
          <label>Don't have an account?</label>
          <Link href="/registration">
            <a className="register" onClick={ onRegisterNow }>Register now</a>
          </Link>
        </div>
      </div>
    </div>
  )
}
export default UserLogin

export const getServerSideProps = async () => {
  return {
    props: {}
  }
}
