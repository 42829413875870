/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useContext } from 'react'
import { useRouter } from 'next/router'
import getConfig from 'next/config'
import { useIonModal } from '@ionic/react'
import Context from '../context'
import { useLoading } from '../LoadingContext'
import Image from 'next/image'
import { useAuthAPI } from '../hooks/api.hooks'
import VerificationCodeErrorModal from '../components/VerificationCodeErrorModal'
import BranchIO from '../utils/branch-io'

import LogoImage from '../public/images/logo_color.svg'
import SVG from '../components/SVG'
import { setPageViewData } from '../utils/analytics'

const { publicRuntimeConfig } = getConfig()
const branchIO: BranchIO = new BranchIO({})

const NewDevice = ({ cpref, onSuccess = null, setLoading = null, user = null }) => {
  const { dispatch, state } = useContext(Context)
  const [code, setCode] = useState('')
  const router = useRouter()
  const authAPICall = useAuthAPI()
  let codeSent = false
  const isnum = /^\d+$/.test(code)
  const ACCOUNT_PAGE_TYPE = 'Account'

  if (!setLoading) {
    const setLoadingFunc = useLoading().setLoading
    setLoading = setLoadingFunc
  }

  const handleDismiss = () => {
    dismiss()
  }
  const [present, dismiss] = useIonModal(VerificationCodeErrorModal, {
    handleDismiss
  })

  useEffect(() => {
    try {
      setPageViewData('Account|MFA', ACCOUNT_PAGE_TYPE, ACCOUNT_PAGE_TYPE)
    } catch (e) {
      console.warn(e)
    }
  }, [])

  useEffect(() => {
    if (!codeSent) {
      authAPICall(
        'user',
        'phone',
        'GET',
        {
          popkey: publicRuntimeConfig.POP_KEY
        },
        {},
        {
          phonenumber: state.user.phone
        }
      ).then(async (response) => {
        if (!response.error) {
          codeSent = true
        }
      })
    }
  }, [])

  const onSignedOut = (response) => {
    if (response.errorCode === 0) {
      router.push('/')
    } else {
      console.error('Error', response.errorMessage)
    }
  }

  const logUserOut = () => {
    if (state.user.UID) {
      const emptyUser = {
        UID: '',
        UidSignature: '',
        UidSignatureTimestamp: ''
      }
      dispatch({ type: 'USER_CUS_DATA', payload: emptyUser })
      // segment.Reset()
      state.gigyaScript.accounts.logout({ callback: onSignedOut })
      router.push('/')
    } else {
      const { redirectedURL, ...spread } = router.query
      redirectedURL
        ? router.push({ pathname: `/${redirectedURL}`, query: { redirectedURL, ...spread } })
        : router.push('/')
    }
  }

  const homeClicked = () => {
    const emptyUser = {
      UID: '',
      UidSignature: '',
      UidSignatureTimestamp: ''
    }
    dispatch({ type: 'USER_CUS_DATA', payload: emptyUser })

    router.push('/')
  }

  const validateDevice = () => {
    authAPICall(
      'user',
      'phone',
      'PUT',
      {
        popkey: publicRuntimeConfig.POP_KEY
      },
      {
        code: code,
        phonenumber: state.user.phone
      },
      {
        ...(user && { customerid: user }),
        appversion: '1'
      }
    ).then(async (data) => {
      if (!data.error) {
        if (data?.devicedid === state.deviceId) {
          if (cpref) {
            const eventAndCustomData = {
              description: 'SignIn Completed'
            }
            const customerEventAlias = state.user ? state.user.UID : ''
            branchIO.logEvent('SignIn Completed', eventAndCustomData, '', customerEventAlias)
          }
          setLoading(false)
          if (onSuccess) {
            onSuccess()
          } else {
            const { redirectedURL, ...spread } = router.query
            redirectedURL
              ? router.push({ pathname: `/${redirectedURL}`, query: { redirectedURL, ...spread } })
              : router.push('/')
          }
        } else {
          setLoading(false)
        }
      } else {
        setLoading(false)
        present()
      }
    })
  }

  return (
    <div className="newdevice-page auth-page">
      <div className="auth-page__container">
        {!onSuccess && <button onClick={logUserOut} className="btn--close" aria-label="close">
          <SVG name="close" label="Close" size="md" />
        </button>}
        {!onSuccess && <div className="auth-page__logo">
          <button onClick={homeClicked}>
            <Image width={150} height={33.5} alt="pOpshelf" src={LogoImage} className="modal-title" />
          </button>
        </div>}
        <h1>New device detected</h1>
        <p>
          As an extra layer of security, we just sent a code to your mobile phone, please enter the code below to sign
          in.
        </p>
        <form>
          <div className="form-wrap">
            <div className="input-wrap">
              <label htmlFor="phone-number" className="label-form">
                Verification code
              </label>
              <input
                id="phone-number"
                type="number"
                pattern="\d*"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              {isnum && code.length > 6 && (
                <span className="icon-input-check">
                  <SVG name="check" label="check" size="md" />
                </span>
              )}
            </div>
            <button
              className="btn__primary btn__primary--full"
              disabled={code.length < 5}
              onClick={(e) => {
                e.preventDefault()
                setLoading(true)
                validateDevice()
              }}
            >
              SUBMIT
            </button>
            <button
              className="btn__secondary btn__secondary--full"
              onClick={(e) => {
                e.preventDefault()
                authAPICall(
                  'user',
                  'phone',
                  'GET',
                  {
                    popkey: publicRuntimeConfig.POP_KEY
                  },
                  {},
                  {
                    phonenumber: state.user.phone
                  }
                )
              }}
            >
              SEND A NEW CODE
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default NewDevice

export const getServerSideProps = async ({ req, query }) => {
  return {
    props: {
      cpref: query.cpref || false
    }
  }
}
